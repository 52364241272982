import validate from 'validate.js'
import passwordValiator from 'password-validator'

export const userNameValidation = {
    required: { value: true, message: 'This is required' },
    maxLength: { value: 50, message: 'Maximum 50 characters' },
    minLength: { value: 5, message: 'Minimum 5 characters' },
    pattern: { value: /^[A-Za-z]+$/i, message: 'Please enter valid username' },
}

export const campaignNameValidation = (name) => {
    const Regex = /^[a-z0-9\-& ]+$/i
    return Regex.test(name)
}

export const requiredTextValidation = {
    required: { value: true, message: 'This is required' },
    minLength: { value: 1, message: 'Minimum 1 characters' },
    pattern: { value: /^[A-Za-z]+$/i, message: 'Please enter valid input' },
}

export const textValidation = {
    validate: {
        validateTextIfEntered: (value) => {
            if (value.length > 0 && value.trim() !== '') {
                if (!value.match(/^[A-Za-z ^<>]+$/i)) {
                    return 'Please enter valid input'
                }

                return true
            }
        },
    },

    //pattern: { value: /^[A-Za-z]+$/i, message: "Please enter valid input" }
}

export const passwordValidation = {
    required: 'Password is required',
    minLength: { value: 8, message: 'Minimum 8 characters' },
    pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        message: 'Password requires a number, lowercase and uppercase',
    },
}

export const emailValidation = {
    required: { value: true, message: 'Email is required' },
    pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter valid email address',
    },
}

export const validURL = (str: string): boolean => {
    let url

    try {
        url = new URL(str)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const checkInputValid = (value: string, type: string): boolean => {
    switch (type) {
        case 'url':
            return validURL(value)
        case 'string':
            if (validate.isString(value) && value.trim().length > 0) {
                return true
            }
            return false
        case 'stringAcceptEmpty':
            if (validate.isString(value)) {
                return true
            }
            return false
        case 'password':
            var schema = new passwordValiator()

            schema
                .is()
                .min(8)
                .has()
                .uppercase()
                .has()
                .lowercase()
                .has()
                .digits()
                .has()
                .symbols()
                .has()
                .not()
                .spaces()
            //const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            //    value
            //);
            const validPassword = schema.validate(value)

            if (validPassword && value.length >= 8) {
                return true
            }
            return false
        case 'alphanumericWithDashSpace':
            var Regex = /^[a-z0-9\- ]+$/i
            return Regex.test(value)
        case 'alphanumericWithDash':
            var Regex = /^[a-z0-9\-]+$/i
            return Regex.test(value)
        // case 'email':
        //     return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        default:
            return false
    }
}

export function checkUrl(name, type, value) {
    if (value !== undefined) {
        let url = value
        if (value.slice(0, 3) == 'www') {
            url = 'https://' + value
        }

        if (value === '') {
            return
        }
        const isValid = checkInputValid(url, type)

        if (!isValid) {
            return 'Invalid URL'
        } else {
            return url
        }
    }

    return
}

export const checkUsernameField = (value: string) => {
    if (value !== undefined) {
        let link = 'deploy.link/' + value
        if (value === '') {
            return
        } else {
            return link
        }
    }
    return
}

export const checkValidEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const checkValidPhoneNumber = (number) => {
    return number.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
}

export const checkValidPasswords = (password: string, confirmPassword: string): boolean => {
    if (password !== confirmPassword) {
        return false
    }

    return true
}

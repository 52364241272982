import React from 'react'
import { useEffect, useState } from 'react'
import Signup from './Auth/Signup'
import Signin from './Auth/Signin'
import VerifyEmail from './Auth/VerifyEmail'
import ForgotPassword from './Auth/ForgotPassword'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../stores/auth/userState'
import { authSpinnerState } from '../stores/auth/authSpinnerState'
import styled from 'styled-components'
import Link from 'next/link'

const LoginLayout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95%;
`

const AuthComponent = ({ uid, emailVerified }: { uid: string; emailVerified: boolean }) => {
    const [formType, updateFormType] = useState<string>('signIn')

    const user = useRecoilValue(userState)
    const setAuthSpinner = useSetRecoilState(authSpinnerState)

    useEffect(() => {
        setAuthSpinner(false)
        if (uid && !emailVerified) {
            updateFormType('verifyEmail')
        }
    }, [])

    return (
        <LoginLayout>
            {!emailVerified && formType == 'verifyEmail' && (
                <VerifyEmail updateFormType={updateFormType} />
            )}

            {formType === 'signUp' && <Signup updateFormType={updateFormType} />}

            {formType === 'signIn' && <Signin updateFormType={updateFormType} />}
        </LoginLayout>
    )
}

export default AuthComponent

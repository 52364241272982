export const industries = () => {
    return [
        'Brewery',
        'Healthcare',
        'Finance',
        'IT',
        'Fashion',
        'Tourism',
        'Education',
        'Hospitality',
        'Retail',
        'eCommerce',
        'Other',
    ]
}

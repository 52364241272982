import { useRouter } from 'next/router'
import { useSetRecoilState } from 'recoil'
import { firebaseClient } from '../../firebaseClient'
import { authSpinnerState } from '../../stores/auth/authSpinnerState'
import { userProfileState } from '../../stores/auth/userProfileState'
import { userState } from '../../stores/auth/userState'
import LogRocket from 'logrocket'
import { Toast } from '../../helpers/Toast'
import { postUserToken } from './postUserToken'
import { NavigationLinks } from '../../stories/Navbar_v2/constants'

export const useLogIn = () => {
    const setAuthSpinner = useSetRecoilState(authSpinnerState)
    const setUserProfile = useSetRecoilState(userProfileState)
    const updateUser = useSetRecoilState(userState)
    const router = useRouter()

    return {
        login: async (email: string, password: string): Promise<string> => {
            try {
                const userCredentials = await firebaseClient
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                const user = userCredentials.user

                if (!user.emailVerified) {
                    router.push('/auth/verify-email')
                }

                setAuthSpinner(true)

                const token: string = await userCredentials.user.getIdToken(true)
                await postUserToken(token, setAuthSpinner)

                const userRef: firebaseClient.firestore.DocumentData = firebaseClient
                    .firestore()
                    .collection('users')
                    .doc(user.uid)
                const userDoc: firebaseClient.firestore.DocumentData = await userRef.get()

                const userData = userDoc.data()

                setUserProfile(userData)

                updateUser(user)

                LogRocket.identify(user.uid, {
                    name: user.displayName,
                    email: user.email,
                })

                setAuthSpinner(false)

                if (userData?.business) {
                    router.push(NavigationLinks.TEAM_DASHBOARD)
                } else {
                    router.push(NavigationLinks.DASHBOARD)
                }
            } catch (err) {
                Toast(err.message, 'error')
                setAuthSpinner(false)
                return 'error'
            }
        },
    }
}

import { Toast } from '../../helpers/Toast'
import { checkUserNameExists } from '../user/requests/checkUserNameExists'
import { checkValidUsername } from '../user/utils/checkValidUsername'

export const useSignUp = () => {
    return {
        signup: async (
            email: string,
            firstname: string,
            lastname: string,
            username: string,
            password: string,
            confirmPassword: string,
            businessName: string,
            industry: string
        ) => {
            try {
                const isUsernameValid: string = checkValidUsername(username)
                if (isUsernameValid !== '') {
                    throw new Error(isUsernameValid)
                }

                const response = await fetch('api/auth/createUser', {
                    method: 'POST',
                    body: JSON.stringify({
                        email,
                        password,
                        firstname,
                        lastname,
                        username,
                        confirmPassword,
                        businessName,
                        industry,
                    }),
                })

                const data = await response.json()

                if (data.error) {
                    throw new Error(data.error)
                }
            } catch (err) {
                throw new Error(err.message)
            }
        },
    }
}

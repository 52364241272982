import { toast } from 'react-toastify'

export const Toast = (message: string, type: string, id?: string): void => {
    switch (type) {
        case 'error':
            if (id && id.trim() != '' && !toast.isActive(id)) {
                toast.error(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                    toastId: id,
                })
            } else if (id == null) {
                toast.error(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                })
            }

            break

        case 'success':
            if (id && id.trim() !== '' && !toast.isActive(id)) {
                toast.success(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                    toastId: id,
                })
            } else if (id == null) {
                toast.success(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                })
            }

            break

        case 'info':
            toast.info(message, {
                position: 'bottom-center',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                className: 'toastBody',
            })
        default:
            break
    }
}

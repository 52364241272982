import React from 'react'
import styled from 'styled-components'
interface Props {
    name: string
    text: string
    checked: boolean
    handleChange: (value: any) => void
}

const Label = styled.label`
    margin-left: 1rem;
    margin-top: 0.25rem;
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
    font-weight: 400;
    font-size: 13px;
`

const RadioButton = ({ name, text, checked, handleChange }: Props) => {
    return (
        <div className="font-primary flex items-center my-4">
            <input type="radio" checked={checked} onChange={handleChange} name={name} id={name} />
            <Label htmlFor={name} dangerouslySetInnerHTML={{ __html: text }}></Label>
        </div>
    )
}

export default RadioButton

import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'

type Props = {}

const BannerLayout = styled.section`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary};
    padding: 2rem;
    color: #ffffff;
    position: relative;
    overflow: hidden;

    .banner-header-text {
        font-size: 28px;
    }

    .banner-sub-text {
        font-size: 18px;
    }

    .image-container {
        position: absolute;
        top: 16%;
        left: 10%;
        width: 100%;
        height: 90%;
    }

    @media (max-width: 600px) {
        height: 510px;
        .image-container {
            position: absolute;
            top: 130px;
            left: -20px;
            width: 110%;
            height: 90%;
        }
    }
`

const Banner = (props: Props) => {
    return (
        <BannerLayout>
            <div className="image-container">
                <Image
                    src="/images/login_splash_bg.svg"
                    layout="fill"
                    alt="splash_1"
                    objectFit="contain"
                    width={100}
                    height={100}
                />
            </div>
            <div className="image-container">
                <Image
                    src="/images/login_splash_1.png"
                    layout="fill"
                    alt="splash_1"
                    objectFit="contain"
                    width={100}
                    height={100}
                />
            </div>
        </BannerLayout>
    )
}

export default Banner

import React, { useState } from 'react'
import Link from 'next/link'
import ButtonSpinner from '../ButtonSpinner'

import { firebaseClient } from '../../firebaseClient'

const VerifyEmail = ({ updateFormType }: { updateFormType: (value: string) => void }) => {
    const [emailLoading, setEmailLoading] = useState<boolean>(false)

    async function sendVerificationEmail() {
        setEmailLoading(true)
        var user = firebaseClient.auth().currentUser

        try {
            await user.sendEmailVerification()
        } catch (error) {
            console.log(error)
            setEmailLoading(false)
        }

        setEmailLoading(false)
    }

    function redirectLogin(): void {
        updateFormType('signIn')
    }

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl text-primary font-medium mt-0 mb-4">verify email</h1>
            <p className="mb-4 text-sm text-grayish text-center">
                You have been sent an verification email
            </p>
            <p className="mb-4 text-sm text-grayish text-center">Verify email to login.</p>
            <button
                type="button"
                className="my-2 py-2 px-4 bg-primaryNavyBlue rounded-full text-white focus:outline-none hover:bg-primaryOrange"
                onClick={redirectLogin}
            >
                Go to Login
            </button>

            <button
                type="button"
                className="my-2 py-2 px-4 bg-primaryNavyBlue rounded-full text-white focus:outline-none hover:bg-primaryOrange"
                onClick={sendVerificationEmail}
            >
                {emailLoading ? (
                    <div className="flex items-center text-sm">
                        <ButtonSpinner height="25px" width="25px" />
                        <span className="ml-4">Sending email...</span>
                    </div>
                ) : (
                    'Resend Email Verification'
                )}
            </button>
            <p className="my-8 text-sm text-grayish text-center">
                <strong>Note: </strong>Your verification email may have landed in spam/junk.
            </p>
        </div>
    )
}

export default VerifyEmail

import React, { useState } from 'react'
import { firebaseClient } from '../../firebaseClient'

import InputField from '../Inputs/DefaultInput'
import ButtonSpinner from '../ButtonSpinner'
import { checkInputValid, checkValidPasswords } from '../../helpers/validation'
import { Toast } from '../../helpers/Toast'
import Checkbox from '../Inputs/Checkbox'
import validator from 'validator'
import { InputBox } from '../../stories/Forms/InputBox'
import { GoogleButton } from '../../stories/Button/GoogleButton'
import { Button } from '../../stories/Button/Button'
import { useRouter } from 'next/router'
import { useSignUp } from '../../lib/auth/useSignUp'
import { AuthContainer } from './AuthContainer'
import { CheckBox } from '../../stories/Forms/CheckBox'
import RadioButton from '../../stories/Forms/RadioButton'
import { SimpleSelect } from '../../stories/Forms/SelectBox'
import { industries } from '../../constants/industries'

interface IForm {
    firstname: string
    lastname: string
    email: string
    company: string
    username: string
    password: string
    confirmPassword: string
    accountType: boolean
    businessName: string
    industry: string
    termsAndConditions: boolean
}

interface IErrors {
    [key: string]: string
}

const Signup = ({ updateFormType }: { updateFormType: (value: string) => void }) => {
    const signup = useSignUp().signup
    const router = useRouter()

    const [formValues, setFormValues] = useState<IForm>({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        username: '',
        password: '',
        confirmPassword: '',
        accountType: true,
        businessName: '',
        industry: '',
        termsAndConditions: false,
    })

    const [errors, setErrors] = useState<IErrors>({})
    const [loading, setLoading] = useState<boolean>(false)

    function handleTextChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    function checkValid(value: string, name: string, type: string, errorMsg: string): void {
        const valid = checkInputValid(value, type)

        if (!valid) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: errorMsg,
                }
            })
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: '',
                }
            })
        }
    }

    function checkPasswords(
        password: string,
        confirmPassword: string,
        name: string,
        errorMsg: string
    ): void {
        const valid = checkValidPasswords(password, confirmPassword)

        if (!valid) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: errorMsg,
                }
            })
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: '',
                }
            })
        }
    }

    async function signUp(e: React.FormEvent): Promise<void> {
        e.preventDefault()

        setErrors({})

        let formErrors = {}

        if (validator.isEmpty(formValues.firstname) || validator.isEmpty(formValues.lastname)) {
            formErrors['firstname'] = 'Please input a valid'
        }

        if (validator.isEmpty(formValues.email) || !validator.isEmail(formValues.email)) {
            formErrors['email'] = 'Please enter a valid email'
        }

        if (validator.isEmpty(formValues.username)) {
            formErrors['username'] = 'Please enter a valid username'
        }

        if (validator.isEmpty(formValues.password)) {
            formErrors['password'] = 'Please enter a valid password'
        }

        if (validator.isEmpty(formValues.confirmPassword)) {
            formErrors['confirmPassword'] = 'Please enter a valid confirm password'
        }

        if (!validator.equals(formValues.confirmPassword, formValues.password)) {
            formErrors['confirmPassword'] = 'Passwords are not the same'
        }

        if (validator.isEmpty(formValues.username)) {
            formErrors['businessName'] = 'Please enter a valid name.'
        }

        if (formValues.termsAndConditions === false) {
            formErrors['termsAndConditions'] = 'Please accept terms and conditions'
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            return
        }

        try {
            setLoading(true)

            const res = await signup(
                formValues.email,
                formValues.firstname,
                formValues.lastname,
                formValues.username,
                formValues.password,
                formValues.confirmPassword,
                formValues.businessName,
                formValues.industry
            )

            router.push('/auth/verify-email')
        } catch (error) {
            if (error.message) {
                Toast(error.message, 'error')
            } else {
                Toast(error, 'error')
            }
            setLoading(false)
        }
    }

    function multiSignIn(provider): void {
        let newProvider = null
        switch (provider) {
            case 'google':
                newProvider = new firebaseClient.auth.GoogleAuthProvider()
                firebaseClient.auth().signInWithRedirect(newProvider)
                break
            case 'facebook':
                newProvider = new firebaseClient.auth.FacebookAuthProvider()
                firebaseClient.auth().signInWithRedirect(newProvider)
                break
            case 'twitter':
                newProvider = new firebaseClient.auth.TwitterAuthProvider()
                firebaseClient.auth().signInWithRedirect(newProvider)
                break
            default:
                break
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.checked,
            }
        })
    }

    const handleRadioChange = (checkedValue: boolean) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                ['accountType']: checkedValue,
            }
        })
    }

    const handleIndustryChange = (opt: string): void => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                ['industry']: opt,
            }
        })
    }

    return (
        <AuthContainer>
            <div>
                <h1>Sign up</h1>
                <div>
                    <p className="sub-text">Already with us? </p>
                    <button
                        type="button"
                        className="sign-up-button"
                        onClick={() => updateFormType('signIn')}
                    >
                        Login
                    </button>
                </div>
            </div>

            <div className="input-container">
                <p className="mt-5 mb-2 text-sm text-grayish font-medium">Your Details</p>
                <div className="grid-col-2">
                    <InputBox
                        placeholder="First name"
                        name="firstname"
                        type="text"
                        width="100%"
                        onChange={handleTextChange}
                        value={formValues.firstname}
                        onBlur={() =>
                            checkValid(
                                formValues.firstname,
                                'firstname',
                                'string',
                                'Please enter valid name.'
                            )
                        }
                        error={errors.firstname}
                    />
                    <InputBox
                        placeholder="Last name"
                        name="lastname"
                        type="text"
                        width="100%"
                        onChange={handleTextChange}
                        value={formValues.lastname}
                        onBlur={() =>
                            checkValid(
                                formValues.lastname,
                                'lastname',
                                'string',
                                'Please enter valid name.'
                            )
                        }
                        error={errors.lastname}
                    />
                </div>
                <InputBox
                    placeholder="Email address"
                    name="email"
                    type="email"
                    onChange={handleTextChange}
                    value={formValues.email}
                    onBlur={() =>
                        checkValid(formValues.email, 'email', 'string', 'Please enter valid email.')
                    }
                    error={errors.email}
                />

                <InputBox
                    placeholder="Unique user name"
                    name="username"
                    type="text"
                    onChange={handleTextChange}
                    value={formValues.username}
                    onBlur={() =>
                        checkValid(
                            formValues.username,
                            'username',
                            'string',
                            'Please enter valid username'
                        )
                    }
                    error={errors.username}
                />

                {formValues['accountType'] && (
                    <p className="username-preview">deploy.link/{formValues.username}</p>
                )}

                <InputBox
                    placeholder="Password"
                    name="password"
                    type="password"
                    onChange={handleTextChange}
                    value={formValues.password}
                    onBlur={() =>
                        checkValid(
                            formValues.password,
                            'password',
                            'password',
                            'Password must contain uppercase, lowercase, number and symbol.'
                        )
                    }
                    error={errors.password}
                />

                <InputBox
                    placeholder="Confirm password"
                    name="confirmPassword"
                    type="password"
                    onChange={handleTextChange}
                    value={formValues.confirmPassword}
                    onBlur={() =>
                        checkPasswords(
                            formValues.password,
                            formValues.confirmPassword,
                            'confirmPassword',
                            "Password's do not match."
                        )
                    }
                    error={errors.confirmPassword}
                />

                <p className="mt-8 text-sm text-grayish font-medium">Account Type</p>

                <div className="grid-col-2">
                    <RadioButton
                        name="accountType"
                        text="Individual Account"
                        checked={formValues['accountType']}
                        handleChange={(e) => {
                            handleRadioChange(true)
                        }}
                    />
                    <RadioButton
                        name="accountType"
                        text="Business Account"
                        checked={!formValues['accountType']}
                        handleChange={(e) => {
                            handleRadioChange(false)
                        }}
                    />
                </div>

                {!formValues['accountType'] && (
                    <div>
                        <p className="mt-4 mb-2 text-sm text-grayish font-medium">
                            Business Details
                        </p>
                        <InputBox
                            placeholder="Business Name"
                            name="businessName"
                            type="text"
                            onChange={handleTextChange}
                            value={formValues.businessName}
                            onBlur={() =>
                                checkValid(
                                    formValues.businessName,
                                    'businessName',
                                    'string',
                                    'Please enter valid business name'
                                )
                            }
                            error={errors.businessName}
                        />
                        {!formValues['accountType'] && (
                            <p className="username-preview">
                                deploy.link/{formValues.businessName}
                            </p>
                        )}
                        <SimpleSelect
                            label=""
                            placeholder="Choose Industry"
                            defaultValue={''}
                            options={industries()}
                            width="100%"
                            onHandleChange={handleIndustryChange}
                        />
                    </div>
                )}
            </div>
            <div className="mt-6"></div>
            <span className="span-or mb-5">or sign up with</span>

            <GoogleButton type="button" width="100%" onClick={() => multiSignIn('google')} />

            <div className="tnc-layout">
                <Checkbox
                    name="termsAndConditions"
                    text="I agree to <a href='https://www.deployable.co/legal'>terms and conditions</a>"
                    checked={formValues['termsAndConditions']}
                    handleChange={handleChange}
                />
            </div>

            <Button type="button" onClick={signUp} variant="secondary" width="100%" size="medium">
                {loading ? (
                    <div className="flex items-center text-sm">
                        <ButtonSpinner height="25px" width="25px" />
                        <span className="ml-4">creating account...</span>
                    </div>
                ) : (
                    'Create account'
                )}
            </Button>
        </AuthContainer>
    )
}

export default Signup

import React from 'react'
import styled from 'styled-components'

type Props = {}

const FooterLayout = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 600px) {
        display: none;
    }
`

const Link = styled.a<{ variant: string }>`
    color: ${(props) =>
        props.variant === 'primary' ? props.theme.colors.primary : props.theme.colors.orange};
    font-size: 12px;
    font-weight: bold;
`

const RightColumn = styled.div`
    justify-self: end;
    a {
        margin-left: 1.5rem;
    }
`

const Footer = (props: Props) => {
    return (
        <FooterLayout>
            <Link variant="primary" href="https://deployable.co">
                Go to main site
            </Link>
            <RightColumn>
                <Link variant="orange" href="https://www.deployable.co/legal">
                    Privacy Policy
                </Link>
                <Link variant="orange" href="https://www.deployable.co/legal">
                    Terms and conditions
                </Link>
            </RightColumn>
        </FooterLayout>
    )
}

export default Footer

import React from 'react'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import Image from 'next/image'
import AuthComponent from '../components/AuthComponent'
import AuthSession from '../helpers/session'
import styled from 'styled-components'
import Footer from '../components/Auth/Footer'
import Banner from '../components/Auth/Banner'
import { useRecoilValue } from 'recoil'
import { userProfileState } from '../stores/auth/userProfileState'

interface Auth {
    user: {
        iss: string
        name: string
        picture: string
        aud: string
        auth_time: number
        user_id: string
        sub: string
        iat: number
        exp: number
        email: string
        email_verified: boolean
        firebase: {
            identities: { [key: string]: any }[]
            sign_in_provider: string
        }
        uid: string
    }
}

export const getServerSideProps: GetServerSideProps = async (
    context: GetServerSidePropsContext
) => {
    try {
        const auth: any = await AuthSession(context)

        let invite: string | string[] = ''

        if (context.query.invite) {
            invite = context.query.invite
        }

        if (!auth.user) return { props: {} as never }

        if (auth.user && auth.user.email_verified) {
            console.log(auth.user)

            context.res.writeHead(302, { Location: '/admin/dashboard' })
            context.res.end()

            return {
                props: {} as never,
            }
        }

        return { props: { auth: auth, invite: invite } }
    } catch (err) {
        return { props: {} as never }
    }
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const LeftContainer = styled.section`
    padding: 2rem;
`

function Home({ auth, invite }: any) {
    const userProfile = useRecoilValue(userProfileState)

    return (
        <Container>
            <LeftContainer>
                <Image
                    src="/images/deployable-logo.png"
                    layout="fixed"
                    alt="deployable"
                    height={23}
                    width={150}
                    className="logo"
                />
                <AuthComponent
                    uid={auth.user ? auth.user.uid : null}
                    emailVerified={auth.user ? auth.user.email_verified : null}
                />
                <Footer />
            </LeftContainer>
            <Banner />
        </Container>
    )
}

export default Home

import styled from 'styled-components'

export const AuthContainer = styled.section`
    width: 50%;
    margin-bottom: 50px;
 

    h1 {
        color: ${(props) => props.theme.colors.primary};
        font-size: 38px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .sub-text {
        color: ${(props) => props.theme.text.light};
        font-size: 16px;
        display: inline-block;
    }

    .sign-up-button {
        color: ${(props) => props.theme.colors.primary};
        font-size: 16px;
        display: inline-block;
        font-weight: bold;
        margin-left: 10px;
    }

    .input-container {
        margin-top: 15px;
    }

    .username-preview {
        margin-top: -0.5rem;
        margin-bottom: 1rem;
        font-size: 11p;x
        color: ${(props) => props.theme.text.light};
    }

    .grid-col-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .tnc-layout {
        display: flex;
        justify-content: start;
        font-weight: normal !important;

        a {
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .span-or {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0.5rem 0;
        font-size: 16px;
        color: ${(props) => props.theme.text.light};
    }

    .forgot-password {
        color: ${(props) => props.theme.text.light};
        display: flex;
        justify-content: center;
        font-size: 11px;
        width: 100%;
        padding-top: 0.5rem;
    }

    @media (max-width: 600px) {
        width: 100%;

        h1 {
            margin-top: 20px;
        }
    }
`

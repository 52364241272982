import React, { useState } from 'react'
import { firebaseClient } from '../../firebaseClient'
import { Toast } from '../../helpers/Toast'
import ButtonSpinner from '../ButtonSpinner'
import { checkInputValid } from '../../helpers/validation'
import { useLogIn } from '../../lib/auth/useLogIn'
import { InputBox } from '../../stories/Forms/InputBox'
import { GoogleButton } from '../../stories/Button/GoogleButton'
import { Button } from '../../stories/Button/Button'
import { AuthContainer } from './AuthContainer'
import Link from 'next/link'

const Signin = ({ updateFormType }: { updateFormType?: (value: string) => void }) => {
    const login = useLogIn().login

    const [formValues, setFormValues] = useState<{ email: string; password: string }>({
        email: '',
        password: '',
    })

    const [errors, setErrors] = useState<{ [key: string]: string }>({})
    const [loading, setLoading] = useState<boolean>(false)

    function handleTextChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    function multiSignIn(provider): void {
        let newProvider = null
        switch (provider) {
            case 'google':
                newProvider = new firebaseClient.auth.GoogleAuthProvider()
                firebaseClient.auth().signInWithRedirect(newProvider)
                break
            case 'facebook':
                newProvider = new firebaseClient.auth.FacebookAuthProvider()
                firebaseClient.auth().signInWithRedirect(newProvider)
                break
            case 'twitter':
                newProvider = new firebaseClient.auth.TwitterAuthProvider()
                firebaseClient.auth().signInWithRedirect(newProvider)
                break
            default:
                break
        }
    }

    async function signIn(): Promise<void> {
        let email = formValues.email
        let password = formValues.password

        if (!checkInputValid(email, 'string')) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['email']: 'Enter valid email.',
                }
            })
        }

        if (!checkInputValid(password, 'string')) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['password']: 'Enter password.',
                }
            })
            return
        }

        try {
            setLoading(true)
            await login(email, password)
            setLoading(false)
        } catch (error) {
            Toast(error.message, 'error')
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <AuthContainer>
            <div>
                <h1>Login</h1>
                <div>
                    <p className="sub-text">Not already with us? </p>
                    <button
                        type="button"
                        className="sign-up-button"
                        onClick={() => updateFormType('signUp')}
                    >
                        Sign up
                    </button>
                </div>
            </div>

            <div className="input-container">
                <InputBox
                    size="medium"
                    width="100%"
                    value={formValues.email}
                    placeholder="Email"
                    name="email"
                    type="email"
                    onChange={handleTextChange}
                    error={errors['email']}
                />
                <InputBox
                    size="medium"
                    width="100%"
                    value={formValues.password}
                    placeholder="Password"
                    name="password"
                    type="password"
                    onChange={handleTextChange}
                    error={errors['password']}
                    onKeyUp={(e: React.KeyboardEvent): void => {
                        if (e.key === 'Enter') {
                            signIn()
                        }
                    }}
                />
            </div>

            <span className="span-or">or</span>

            <GoogleButton type="button" width="100%" onClick={() => multiSignIn('google')} />

            <Button type="button" onClick={signIn} variant="secondary" width="100%" size="medium">
                {loading ? (
                    <div className="flex items-center text-sm">
                        <ButtonSpinner height="25px" width="25px" />
                        <span className="ml-4">Logging in...</span>
                    </div>
                ) : (
                    'Signin'
                )}
            </Button>

            <div className="flex items-center justify-center">
                <Link href="/forgot-password" replace>
                    <a className="mt-2 text-center">Forgot Password</a>
                </Link>
            </div>
        </AuthContainer>
    )
}

export default Signin

import validate from 'validate.js'


export const checkValidUsername = (username:string):string => {

    if(!username){
        return "Username cannot be empty or less than 3 characters."
    }

    if(validate.isEmpty(username) || !validate.isString(username) || username.trim().length < 3){
        return "Username cannot be empty or less than 3 characters."
    }

    const Regex = /^[a-z0-9\-]+$/i;

    if(!Regex.test(username)){
        return "Username has invalid characters."
    }

    const lowercaseUsername = username.toLowerCase()

    switch(lowercaseUsername){
        case "home":
        case "campaign":
        case "forms":
        case "account":
        case "branding":
        case "mobile":
        case "form":
        case "template":
        case "templates":
        case "nav":
        case "add":
        case "new":
        case "delete":
        case "edit":
        case "lib":
        case "user":
        case "models":
        case "upload":
        case "default":
        case "custom":
        case "view":
        case 'dashboard':
        case "common":
        case 'auth':
        case 'action':
        case "input":
        case "payment":
        case 'checkout':
        case "content":
        case "live":
        case "icons":
        case "widgets":
        case "games":
        case "game":
        case "file":
        case "files":
        case "email":
        case "preview":
        case "public":
            return "This username is not permitted. Try again with another username."
        default:
            return ""
    }


}